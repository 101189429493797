import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

const Responsive = props => {
  const { desktopComponent, mobileComponent } = props;

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 461 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 460 });
    return isMobile ? children : null;
  };

  return (
    <>
      <Desktop>{desktopComponent}</Desktop>
      <Mobile>{mobileComponent}</Mobile>
    </>
  );
};

Responsive.propTypes = {
  desktopComponent: PropTypes.element,
  mobileComponent: PropTypes.element,
};

export default Responsive;
