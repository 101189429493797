import JsEncrypt from 'jsencrypt';
import * as CryptoJS from 'crypto-js';

const encrypt = (data) => {
  const crypt = new JsEncrypt();
  crypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
  const text = crypt.encrypt(data);
  return text;
};
const sHA256 = (data) => {
  return CryptoJS.SHA256(data).toString();
};

const encryptThirdRSA = (data) => {
  const crypt = new JsEncrypt();
  crypt.setPublicKey(process.env.REACT_APP_THIRD_PUBLIC_KEY);
  const text = crypt.encrypt(JSON.stringify(data));
  return text;
};

const decryptAes = (data, hashKey) => {
  try {
    if (typeof data === 'undefined') {
      return null;
    }
    const dataDecrypt = CryptoJS.AES.decrypt(
      new Buffer(data, 'base64').toString(),
      hashKey,
    );
    return dataDecrypt.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    return null;
  }
};

const encryptCryptoJsAes = (data, key, iv) => {
  try {
    if (typeof data === 'undefined') {
      return null;
    }
    return CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }).toString();
  } catch (err) {
    return null;
  }
};

export { encrypt, decryptAes, encryptThirdRSA, encryptCryptoJsAes, sHA256 };
