import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

// Components
import Button from '../../components/Button';
import LoginSeparador from '../LoginSeparador';

// Utils
import { login } from '../../utils/services';
import { rutFormater, rutValidator } from '../../utils/rutHelpers';
import { redirectExternalUrl, getParamsUrl } from '../../utils/navigate';
import useKeyPress from '../../utils/hooks/useKeyPress';

// Assets
import arrowRight from '../../assets/images/icn-arrow-right.svg';

// Styles
import './styles.scss';

const LoginForm = props => {
  const { setLoginFeedback, setLoginStage, placeholderDocument, isDisabled } =
    props;

  const lengthDocument = 10;
  const lengthPassword = 6;
  const textBtnLogin = 'INGRESAR';
  const [documentOk, setDocumentOk] = useState('');
  const [passwordOk, setPasswordOk] = useState('');

  const [formValues, setFormValues] = useState({
    document: '',
    password: '',
  });

  const [errorDocument, setErrorDocument] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [disabled, setDisabled] = useState(true);

  const elFormRef = useRef();
  const keyPressed = useKeyPress('Enter', elFormRef);

  const isValidForm = () => !!(documentOk && passwordOk);

  const validateRut = e => {
    e.preventDefault();
    const re = /^[0-9-kK\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setFormValues({
        ...formValues,
        document: e.target.value,
      });
    }

    setFormValues({
      ...formValues,
      document: rutFormater(e.target.value),
    });

    if (!rutValidator(e.target.value)) {
      setErrorDocument('El RUT no es válido');
      setDocumentOk(false);
    } else {
      setErrorDocument('');
      setDocumentOk(true);
    }
    checkSubmit();
  };

  const checkSubmit = () => {
    if (isValidForm()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkPassword = e => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setFormValues({
        ...formValues,
        password: e.target.value,
      });
    }
    if (e.target.value.length < 6) {
      setErrorPassword('Ingresa tu Clave Internet');
      setPasswordOk(false);
    } else {
      setErrorPassword('');
      setPasswordOk(true);
    }
    checkSubmit();
  };

  const authentication = async () => {
    setLoginStage('loading');
    const { document, password } = formValues;
    let params = {};
    let loginFeedback = {
      title: 'Por el momento no es posible iniciar sesión',
      subtitle: 'Por favor inténtalo más tarde.',
      description: '',
      showBtnBack: true,
    };

    if (window.location.search !== '') {
      params = getParamsUrl(window.location.search);
    }

    const request = {
      body: {
        documentNumber: document.replace('-', ''),
        documentType: '1',
        password,
      },
      aes: {
        AESKey: uuid().toString().replace(/-/g, ''),
        iv: uuid().toString().replace(/-/g, '').substr(0, 16),
      },
    };

    try {
      await login(request).then(response => {
        loginFeedback.title = 'Inicio de sesión exitoso';
        loginFeedback.subtitle = '';
        if (params['urlCallback'] !== '' || params['url_callback'] !== '') {
          const { payload } = response.data.data;
          const encodePayload = encodeURIComponent(payload);
          const urlCallback = `${
            params['urlCallback'] || params['url_callback']
          }?payload=${encodePayload}`;
          redirectExternalUrl(urlCallback);
        }
      });
    } catch (error) {
      if (error.response.status === 409) {
        loginFeedback = getLoginFeedback(error.response.data.data.code);
      } else if (error.response.status === 423) {
        loginFeedback = getLoginFeedback(error.response.data.data.code);
      } else if (error.response.status === 401) {
        loginFeedback = getLoginFeedback(error.response.data.data.code);
      } else {
        loginFeedback = getLoginFeedback(error.response.data.data.code);
      }
    } finally {
      setLoginFeedback(loginFeedback);
      setLoginStage('login-feedback');
    }
  };

  const getLoginFeedback = code => {
    if (code === 418) {
      return {
        title: 'Verifica tus datos',
        subtitle: 'El RUT o la Clave Internet no son válidos.',
        description:
          'Recuerda que en un número determinado de intentos, tu Clave Internet será bloqueada.',
        showBtnBack: true,
        recovery: false,
        recoveryLink: true,
      };
    } else if (code === 415) {
      return {
        title: 'Tu Clave Internet ha sido bloqueada',
        subtitle:
          'Por motivos de seguridad, tu clave ha sido bloqueda. No te preocupes, puedes recuperarla.',
        description: '',
        showBtnBack: false,
        recovery: true,
        recoveryLink: false,
      };
    } else {
      return {
        title: 'En este momento no es posible iniciar sesión',
        subtitle: 'Por favor inténtalo más tarde.',
        description: '',
        showBtnBack: true,
        recovery: false,
        recoveryLink: false,
      };
    }
  };

  useEffect(() => {
    const isNotValid = !isValidForm();

    if (isDisabled) {
      setDisabled(isDisabled);
    } else {
      setDisabled(isNotValid);
    }
  }, [isDisabled, isValidForm]);

  useEffect(() => {
    if (isValidForm() && keyPressed) {
      authentication();
    }
  }, [keyPressed]);

  return (
    <form
      className="login-form-container"
      ref={elFormRef}
      noValidate
      autoComplete="off">
      <div className="input-group">
        <label>Ingresa tu RUT</label>
        <input
          id="loginDocumentInput"
          type="text"
          name="document"
          className="form-control"
          value={formValues.document}
          onPaste={e => {
            e.preventDefault();
          }}
          onCopy={e => {
            e.preventDefault();
          }}
          onCut={e => {
            e.preventDefault();
          }}
          onChange={validateRut}
          onKeyUp={validateRut}
          maxLength={lengthDocument}
          minLength={lengthDocument}
          placeholder={placeholderDocument}
          disabled={isDisabled}
          autoComplete="new-password"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />

        {errorDocument && <p className="text-error ">{errorDocument}</p>}
      </div>

      <LoginSeparador />

      <div className="input-group">
        <input
          id="loginPasswordInput"
          type="password"
          name="password"
          className="form-control"
          value={formValues.password}
          maxLength={lengthPassword}
          minLength={lengthPassword}
          onKeyUp={checkPassword}
          onChange={checkPassword}
          onPaste={e => {
            e.preventDefault();
          }}
          onCopy={e => {
            e.preventDefault();
          }}
          onCut={e => {
            e.preventDefault();
          }}
          placeholder="Tu clave es de 6 dígitos"
          disabled={isDisabled}
          autoComplete="new-password"
        />

        {errorPassword && <p className="text-error ">{errorPassword}</p>}
      </div>

      <div className="input-group" style={{ marginBottom: '32px' }}>
        <Button
          label={textBtnLogin}
          disabled={disabled}
          action={authentication}
          btnType={'submit'}
        />
      </div>

      <div className="input-group" style={{ marginBottom: '0px' }}>
        <a
          className="text-link"
          rel="nofollow noopener noreferrer"
          href={process.env.REACT_APP_URL_CHANGE_PASSWORD}
          target="__blank">
          <span>
            Crea o recupera tu clave
            <img src={arrowRight} alt="Crea o recupera tu clave " />
          </span>
        </a>
      </div>
    </form>
  );
};
LoginForm.propTypes = {
  setLoginFeedback: PropTypes.func,
  setLoginStage: PropTypes.func,
  placeholderDocument: PropTypes.string,
  isDisabled: PropTypes.bool,
};
export default LoginForm;
