import PropTypes from 'prop-types';
import './style.scss';

import logo from '../../assets/images/logo.png';

const TitleDesktop = props => {
  const { title, subtitle } = props;

  return (
    <div className="logo-container">
      <img src={logo} alt="logo-cmrpuntos" />

      <div className="logo-content">
        <h2 className="title-text">{title}</h2>
        <p className="subtitle-text">{subtitle}</p>
      </div>
    </div>
  );
};

TitleDesktop.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default TitleDesktop;
