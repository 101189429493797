import Loader from '../Loader';
import './styles.scss';

const LoginContainer = () => {
  return (
    <div className="loader-container">
      <h4>Iniciando Sesión</h4>
      <Loader></Loader>
    </div>
  );
};

export default LoginContainer;
