import PropTypes from 'prop-types';
import './style.scss';

// NO ELIMINAR se usa para crear los asset en la carpeta dist
import logo from '../../assets/images/logo.png';

const TitleMobile = props => {
  const { title, subtitle } = props;

  return (
    <div className="login-logo-mobile">
      <img src={logo} alt="logo-cmrpuntos.cl" />
      <p className="title-text">{title}</p>
      <p className="subtitle-text">{subtitle}</p>
    </div>
  );
};

TitleMobile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default TitleMobile;
