import PropTypes from 'prop-types';
import './styles.scss';

const Button = props => {
  const { action, label, disabled, imgSrc } = props;
  let { btnClass, btnType } = props;

  if (btnClass === undefined) {
    btnClass = 'btn';
  }
  if (btnType === undefined) {
    btnType = 'button';
  }

  return (
    <button
      disabled={disabled}
      className={btnClass}
      onClick={action}
      type={btnType}>
      {label} {imgSrc}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  btnClass: PropTypes.string,
  btnType: PropTypes.string,
  imgSrc: PropTypes.element,
  action: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
