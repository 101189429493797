import * as React from 'react';
import { createPortal } from 'react-dom';

// Utils
import { ariaAttr } from '../../utils/aria';
import HtmlParser from '../HtmlParser';
import useTooltip from './Tooltip.utils';
import StyledTooltip from './Tooltip.styles';

// Definitions
const Portal = props => {
  return createPortal(props.children, document.body);
};

const Tooltip = props => {
  const {
    id,
    text,
    placement = 'bottom',
    showArrow = true,
    space = 10,
    delay,
    disabled = false,
    children,
  } = props;

  const { open, position, tooltipRef, show, hide, updatePosition } = useTooltip(
    {
      placement,
      space,
    },
  );

  const handleMouseOver = e => {
    updatePosition(e.currentTarget);
    show();
  };
  const handleMouseOut = () => hide();

  const testId = id && `${id}-tooltip`;
  const ariaAttrs = {
    ...ariaAttr('hidden', !open),
  };

  return (
    <>
      {disabled
        ? children
        : React.cloneElement(children, {
            ...(open && ariaAttr('describedby', id)),
            key: +open,
            onMouseOver: handleMouseOver,
            onMouseOut: handleMouseOut,
          })}
      {disabled || (
        <Portal>
          <StyledTooltip
            role="tooltip"
            id={id}
            data-testid={testId}
            open={open}
            placement={placement}
            positionX={position.x}
            positionY={position.y}
            showArrow={showArrow}
            delay={delay}
            ref={tooltipRef}
            {...ariaAttrs}
          >
            {HtmlParser({ text })}
          </StyledTooltip>
        </Portal>
      )}
    </>
  );
};

export default Tooltip;
