import { useCallback, useEffect, useState } from 'react';

const useKeyPress = (targetKey, ref) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    },
    [targetKey],
  );

  const upHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    },
    [targetKey],
  );

  useEffect(() => {
    const refElem = ref?.current || window;
    refElem?.addEventListener('keydown', downHandler);
    refElem?.addEventListener('keyup', upHandler);
    return () => {
      refElem?.removeEventListener('keydown', downHandler);
      refElem?.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, ref, upHandler]); // La matriz vacía garantiza que el efecto solo se ejecute al montar y desmontar
  return keyPressed;
};
export default useKeyPress;
