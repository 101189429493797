import styled, { css } from 'styled-components';

import {
  RoundBorderMixin,
  TextDefaultFontMixin,
  TextWeightMixin,
} from '../../utils/styles/Mixins';
import { flipPlacement } from './Tooltip.utils';

// Definitions

const TootltipArrowStyleMixin = (placement) => {
  const ArrowBaseStyle = css`
    &:after,
    &:before {
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
  `;

  if (placement === 'bottom') {
    return css`
      ${ArrowBaseStyle}

      &:after,
      &:before {
        bottom: 100%;
        left: 50%;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: var(--color-white);
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(201, 212, 223, 0);
        border-bottom-color: var(--color-border-default);
        border-width: 9px;
        margin-left: -9px;
      }
    `;
  }

  if (placement === 'topleft') {
    return css`
      ${ArrowBaseStyle}

      &:after,
      &:before {
        top: 100%;
        left: 92%;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: var(--color-white);
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(201, 212, 223, 0);
        border-top-color: var(--color-border-default);
        border-width: 9px;
        margin-left: -9px;
      }
    `;
  }

  if (placement === 'top') {
    return css`
      ${ArrowBaseStyle}

      &:after,
      &:before {
        top: 100%;
        left: 50%;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: var(--color-white);
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(201, 212, 223, 0);
        border-top-color: var(--color-border-default);
        border-width: 9px;
        margin-left: -9px;
      }
    `;
  }

  if (placement === 'left') {
    return css`
      ${ArrowBaseStyle}

      &:after,
      &:before {
        left: 100%;
        top: 50%;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: var(--color-white);
        border-width: 8px;
        margin-top: -8px;
      }
      &:before {
        border-color: rgba(201, 212, 223, 0);
        border-left-color: var(--color-border-default);
        border-width: 9px;
        margin-top: -9px;
      }
    `;
  }

  return css`
    ${ArrowBaseStyle}

    &:after,
    &:before {
      right: 100%;
      top: 50%;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-right-color: var(--color-white);
      border-width: 8px;
      margin-top: -8px;
    }
    &:before {
      border-color: rgba(201, 212, 223, 0);
      border-right-color: var(--color-border-default);
      border-width: 9px;
      margin-top: -9px;
    }
  `;
};

const Tooltip = styled.div`
  ${TextDefaultFontMixin}
  ${TextWeightMixin('regular')}
  ${RoundBorderMixin}
  white-space: pre-line;

  position: absolute;
  display: inline-block;
  top: ${p => p.positionY}px;
  left: ${p => p.positionX}px;
  font-size: 12px;
  max-width: 250px;
  padding: 12px 16px;
  background: var(--color-white);
  backdrop-filter: blur(27.1828px);
  border: 0.5px solid var(--color-border-default);
  box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
    0px 6px 12px -3px rgba(0, 51, 102, 0.075);
  pointer-events: none;
  z-index: 999;
  opacity: ${p => (p.open ? 1 : 0)};
  transition-property: transform, opacity !important;
  transition-duration: 0.06s !important;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  transition-delay: ${p => (p.open ? p.delay : 0.02)}s !important;
  transform-origin: ${p => flipPlacement(p.placement)};
  transform: scale(${p => (p.open ? 1 : 0.7)});
  color: #5c6166;

  ${p => p.showArrow && TootltipArrowStyleMixin(p.placement)}
`;

export default Tooltip;
