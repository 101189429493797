import { useEffect, useState, useCallback } from 'react';
import Cookies from 'js-cookie';

import { pageValid, oauth } from '../../utils/services';
import { redirectExternalUrl, getParamsUrl } from '../../utils/navigate';
import { parse } from 'query-string';
// Components
import Title from '../Title';
import LoginForm from '../../components/LoginForm';
import LoginFeedback from '../LoginFeedback/LoginFeedback';
import LoaderContainer from '../LoaderContainer';

// Styles
import './styles.scss';

const LoginContainer = () => {
  let component;
  const placeholderDocument = 'RUT';
  const title = 'Ingresa a tu cuenta';
  const subtitle = 'Revisa tus puntos, canjes y más';

  const [enableButton, setEnableButton] = useState(true);
  const [loginStage, setLoginStage] = useState('login-form');

  
  const [loginFeedback, setLoginFeedback] = useState({
    title: 'En este momento no es posible iniciar sesión',
    subtitle: 'Por favor inténtalo más tarde.',
    description: '',
    showBtnBack: true,
    recovery: false,
  });

  const params = window.location.search
    ? getParamsUrl(window.location.search)
    : '';

  const setValue = useCallback(async () => {
    if (params.urlCallback || params.url_callback) {
      const url = new URL(params.urlCallback || params.url_callback);

      const includeUrl = url.origin
        .toString()
        .includes('tienda-staging.cmrpuntos.cl');

      includeUrl && setEnableButton(false);

      const request = {
        body: { url: url.origin.toString() },
      };

      await pageValid(request)
        .then(response => {
          const value = response.data.data.valid;
          value ? setEnableButton(false) : setEnableButton(true);
        })
        .catch(() => {
          setEnableButton(true);
        });
    } else {
      setEnableButton(true);
    }
  }, []);

  const loginOauth = useCallback(async payload => {
    try {
      await oauth(payload).then(response => {
        if (params['url_callback'] !== '') {
          const urlCallbackParam =
            params['urlCallback'] || params['url_callback'];
          const redirectPath = params['redirectPath'];
          const { payload } = response.data.data;
          const encodePayload = encodeURIComponent(payload);
          const urlCallback = `${urlCallbackParam}?payload=${encodePayload}`;
          setRedirectCookie(redirectPath);
          redirectExternalUrl(urlCallback);
        }
      });
    } catch (error) {
      setLoginStage('login-form');
    }
  }, []);

  const setRedirectCookie = redirectPath => {
    if (redirectPath) {
      const pathNameSearch = redirectPath.includes('/search');
      const price = params['price'];

      redirectPath =
        pathNameSearch && price
          ? `${redirectPath}&q=""&price=${price}`
          : redirectPath;
      const inTwoMinutes = new Date(new Date().getTime() + 2 * 60 * 1000);
      Cookies.set('redirect', redirectPath, {
        domain: '.cmrpuntos.cl',
        expires: inTwoMinutes,
      });
    } else {
      console.error('error creating cookie redirect');
    }
  };

  useEffect(() => {
    const { payload } = parse(window.location.search);
    localStorage.removeItem('logged');
    if (payload) {
      setLoginStage('loading');
      loginOauth(payload);
    }

    setValue();
  }, [setValue]);

  const handleRecovery = () => {
    window.open('https://site-staging.cmrpuntos.cl/clave', '_blank');
    setLoginStage('login-form');
  };

  if (loginStage === 'login-form') {
    component = (
      <>
        <Title title={title} subtitle={subtitle} />

        <div className="separator-line" />

        <LoginForm
          setLoginStage={setLoginStage}
          setLoginFeedback={setLoginFeedback}
          placeholderDocument={placeholderDocument}
          isDisabled={enableButton}
        />
      </>
    );
  }

  if (loginStage === 'loading') {
    component = <LoaderContainer />;
  }

  return loginStage !== 'login-feedback' ? (
    <div className="login-container">
      <div className="login-wrapper">{component}</div>
    </div>
  ) : (
    <div className="login-container">
      <LoginFeedback
        loginFeedback={loginFeedback}
        setLoginStage={setLoginStage}
        handleRecovery={handleRecovery}
      />
    </div>
  );
};

export default LoginContainer;
