import { useState, useRef, useEffect, useCallback } from 'react';

export const flipPlacement = (placement) => {
  if (placement === 'left') return 'right';
  if (placement === 'right') return 'left';
  if (placement === 'top') return 'bottom';
  if (placement === 'bottom') return 'top';
  if (placement === 'topleft') return 'bottom';

  return placement;
};

const isHorizontal = (placement) => {
  return placement === 'left' || placement === 'right';
};

const isVertical = (placement) => {
  return (
    placement === 'top' || placement === 'bottom' || placement === 'topleft'
  );
};

const restrict = (position, boundaries) => {
  const restrictedPosition = { ...position };

  if (restrictedPosition.x < boundaries.left)
    restrictedPosition.x = boundaries.left;
  else if (restrictedPosition.x > boundaries.right)
    restrictedPosition.x = boundaries.right;

  if (restrictedPosition.y < boundaries.top)
    restrictedPosition.y = boundaries.top;
  else if (restrictedPosition.y > boundaries.bottom)
    restrictedPosition.y = boundaries.bottom;

  return restrictedPosition;
};

const getScrollPosition = () => {
  const doc = document.documentElement;
  const scrollY = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

  return scrollY;
};

const getPosition = (options) => {
  const { elementTarget, tooltip, placement, space } = options;

  let point = { x: 0, y: 0 };
  const elementRect = elementTarget.getBoundingClientRect();
  const scrollY = getScrollPosition();
  const boundaries = {
    left: space,
    top: space,
    right: document.body.clientWidth - (tooltip.clientWidth + space),
    bottom: window.innerHeight + scrollY - (tooltip.clientHeight + space),
  };

  let recurCount = 0;

  return (function recursive(p) {
    recurCount += 1;

    switch (p) {
      case 'left':
        point.x = elementRect.left - (tooltip.offsetWidth + space);
        point.y =
          elementRect.top +
          scrollY +
          (elementTarget.offsetHeight - tooltip.offsetHeight) / 2;
        break;
      case 'right':
        point.x = elementRect.right + space;
        point.y =
          elementRect.top +
          scrollY +
          (elementTarget.offsetHeight - tooltip.offsetHeight) / 2;
        break;
      case 'top':
        point.x =
          elementRect.left +
          (elementTarget.offsetWidth - tooltip.offsetWidth) / 2;
        point.y = elementRect.top + scrollY - (tooltip.offsetHeight + space);
        break;
      case 'topleft':
        point.x =
          elementRect.left +
          (elementTarget.offsetWidth - tooltip.offsetWidth) +
          12;
        point.y = elementRect.top + scrollY - (tooltip.offsetHeight + space);
        break;
      default:
        point.x =
          elementRect.left +
          (elementTarget.offsetWidth - tooltip.offsetWidth) / 2;
        point.y = elementRect.bottom + scrollY + space;
    }

    if (recurCount < 3)
      if (
        (isHorizontal(p) &&
          (point.x < boundaries.left || point.x > boundaries.right)) ||
        (isVertical(p) &&
          (point.y < boundaries.top || point.y > boundaries.bottom))
      ) {
        point = { ...recursive(flipPlacement(p)) };
      }

    // restrict to rect boundary
    point = { ...restrict(point, boundaries) };

    return point;
  })(placement);
};

const useTooltip = (options) => {
  const { space, placement } = options;

  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);

  const updatePosition = (target) => {
    const elementTarget = target;
    const tooltip = tooltipRef.current;

    if (!(elementTarget && tooltip)) return;

    const newPosition = getPosition({
      elementTarget,
      tooltip,
      placement,
      space,
    });

    setPosition(newPosition);
  };

  const handleOnScroll = useCallback(() => {
    setShow(false);
  }, []);

  // Hides tooltip when scrolling
  useEffect(() => {
    if (show) {
      window.addEventListener('scroll', handleOnScroll);
    } else {
      window.removeEventListener('scroll', handleOnScroll);
    }
  }, [show, handleOnScroll]);

  // Remove scroll listener on unmoount
  useEffect(() => {
    return () => window.removeEventListener('scroll', handleOnScroll);
  }, [handleOnScroll]);

  return {
    open: show,
    position,
    tooltipRef,
    show: () => setShow(true),
    hide: () => setShow(false),
    updatePosition,
  };
};

export default useTooltip;
