import htmlParser from '../../utils/htmlParser';
import sanitize from '../../utils/sanitize';

const HtmlParser = props => {
  const { text, options } = props;
  const textSanitized = sanitize(text, options);

  return <>{htmlParser(textSanitized)}</>;
};

export default HtmlParser;
