/**
 *
 * @param {string} value
 * @return {string}
 */
const rutFormater = (value) => {
  const rut = value.replace(/[^0-9kK]+/g, '').toUpperCase();
  if (rut.length <= 1) {
    return rut;
  } else {
    let result = rut.slice(-4, -1) +
      '-' + rut.substr(rut.length - 1);
    for (let i = 4; i < rut.length; i += 3) {
      result = `${rut.slice(-3 - i, -i)}${result}`; // format with dot
    }
    return result;
  }
};

/**
 *
 * @param {string} value
 * @return {boolean}
 */
const rutValidator = (value) => {
  if (value && value === '') {
    return false;
  }
  let rut = value.replace('-', '');
  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
    return false;
  }
  rut = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
  if (rut.length < 7) {
    return false;
  }
  let t = parseInt(rut.slice(0, -1), 10);
  let m = 0;
  let s = 1;
  while (t > 0) {
    s = (s + (t % 10) * (9 - m++ % 6)) % 11;
    t = Math.floor(t / 10);
  }
  const v = s > 0 ? '' + (s - 1) : 'K';
  if ((v === rut.slice(-1)) !== true) {
    return false;
  }
  return true;
};

export {
  rutFormater,
  rutValidator,
};
