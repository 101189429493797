import PropTypes from 'prop-types';
import Responsive from '../Responsive';
import TitleDesktop from './TitleDesktop';
import TitleMobile from './TitleMobile';
import './style.scss';

const Title = props => {
  const { title, subtitle } = props;

  const mobile = <TitleMobile title={title} subtitle={subtitle} />;
  const desktop = <TitleDesktop title={title} subtitle={subtitle} />;
  return <Responsive desktopComponent={desktop} mobileComponent={mobile} />;
};

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Title;
