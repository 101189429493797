import sanitizeHtml from 'sanitize-html';

const DEFAULT_OPTIONS = {
  allowedTags: ['b', 'i', 'em', 'strong'],
};

const sanitize = (text, settings) => {
  const options = { ...DEFAULT_OPTIONS, ...settings };
  return sanitizeHtml(text, options);
};

export default sanitize;
