import HelpTip from '../HelpTip';

// NO ELIMINAR se usa para crear los asset en la carpeta dist
import LogoFalabellaCMRPuntos from '../../assets/images/icn-falabella-group-login.svg';
import './styles.scss';

const LoginSeparador = () => {
  return (
    <div className="login-separador-container">
      <p>
        Ingresa tu Clave de Internet
        <HelpTip
          value={`Si eres cliente de <b>Banco Falabella</b>, ingresa con tu clave de internet para la web o app de Banco Falabella.
            \n Si eres cliente <b>CMR Puntos</b> ingresa con tu clave de internet CMR Puntos.`}
          placement="top"
          style={{ marginLeft: '14px' }}
        />
      </p>

      <div className="login-content-logos">
        <img src={LogoFalabellaCMRPuntos} alt="login-cmrpuntos.cl" />
      </div>
    </div>
  );
};

export default LoginSeparador;
