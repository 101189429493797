/**
 *
 * @param {string} url
 * @return {void}
 */
const redirectExternalUrl = (url) => {
  window.location.replace(url);
};
/**
 *
 * @param {string} url
 * @return {string }
 */
const getParamsUrl = (url) => {
  const params = url.replace('?', '').split('&');
  const maps = {};
  params.map(function (param) {
    return (maps[param.split('=')[0].toString()] = decodeURIComponent(
      param.split('=')[1].toString(),
    ));
  });
  return maps;
};

export { redirectExternalUrl, getParamsUrl };
