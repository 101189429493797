import PropTypes from 'prop-types';
import Button from '../Button';

// Assets
import arrowRight from '../../assets/images/icn-arrow-right.svg';

// Styles
import './styles.scss';

const LoginFeedback = props => {
  const textBtnBack = 'VOLVER';
  const { setLoginStage, loginFeedback, handleRecovery } = props;

  const clickback = () => {
    setLoginStage('login-form');
  };

  return (
    <div className="login-feedback-container">
      <h4 className="feedback-title-text">{loginFeedback.title}</h4>

      <div className="feedback-texts">
        <p className="feedback-subtitle-text">{loginFeedback.subtitle}</p>

        {loginFeedback.description && (
          <p className="feedback-description-text">
            {loginFeedback.description}
          </p>
        )}
      </div>

      {loginFeedback.showBtnBack && (
        <Button
          btnClass={`${!loginFeedback.recoveryLink ? 'btn' : 'btn-tertiary'}`}
          label={textBtnBack}
          action={clickback}
        />
      )}

      {loginFeedback.recovery && (
        <Button
          label="RECUPERAR CLAVE"
          btnClass="btn"
          action={handleRecovery}
        />
      )}

      {loginFeedback.recoveryLink && (
        <a
          className="text-link"
          rel="nofollow noopener noreferrer"
          href={process.env.REACT_APP_URL_CHANGE_PASSWORD}
          target="__blank"
          style={{ marginBottom: '0px' }}>
          <span>
            Crea o recupera tu clave
            <img src={arrowRight} alt="Crea o recupera tu clave " />
          </span>
        </a>
      )}
    </div>
  );
};

LoginFeedback.propTypes = {
  setLoginStage: PropTypes.func,
  loginFeedback: PropTypes.object,
};
export default LoginFeedback;
