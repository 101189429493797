import axios from 'axios';
import { encryptCryptoJsAes, encrypt, sHA256 } from './cipher';

const headers = {
  'x-channel': 'Web',
  'x-commerce': 'Banco',
  'x-country': 'CL',
  'x-paytrue-commerce': 'CMR',
  'x-sso': 'true',
  'x-paytrue-channel': 'Loyalty-WEB',
  'Content-Type': 'application/json',
  'x-forwarded-for': '190.153.235.116, 10.78.255.9, 190.21.76.117', // Es sobrescrito por KONG
};

const login = async requestLogin => {
  const { body, aes } = requestLogin;
  const request = {
    userData: encryptCryptoJsAes(JSON.stringify(body), aes.AESKey, aes.iv),
  };
  headers['x-session-key'] = encrypt(JSON.stringify(aes));
  const hash = sHA256(JSON.stringify(body));
  headers['x-signature'] = encryptCryptoJsAes(hash, aes.AESKey, aes.iv);
  const loginConfig = {
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URI}/v1/authenticate`,
    data: request,
    headers,
  };

  return await axios(loginConfig)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

const pageValid = async requestLogin => {
  const { body } = requestLogin;

  const validConfig = {
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URI}/v1/page/valid`,
    data: body,
    headers,
  };

  return await axios(validConfig)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

const oauth = async token => {
  const decrypt = decodeURIComponent(token);
  headers['x-auth-id'] = decrypt;

  const oauthConfig = {
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URI}/v1/validate/authid`,
    data: undefined,
    headers,
  };

  return await axios(oauthConfig)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export { login, pageValid, oauth };
